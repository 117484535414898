import React, { useEffect, useState } from "react";
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  InputGroupAddon,
  Row,
  Spinner,
  Input,
  Collapse,
} from "reactstrap";
import styles from "../../donate/donate.module.scss";
import { gql, useQuery } from "@apollo/client";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";
import "react-accessible-accordion/dist/fancy-example.css";
import { ChevronDown, ChevronUp, X } from "react-feather";
import Check from "../../../assets/images/svgs/check.svg";
import Back from "../../../assets/images/svgs/back.svg";
import { ToastContainer, toast } from "react-toastify";
import SearchLocationInput from "../../common/SearchLocationInput";
import useFormattedPhone from "../../../helpers/hooks/useFormattedPhone";
import CategoryDropdown from "./CategoryDropdown";
import {
  LAST_USED_ADDRESS,
  LAST_USED_PHONE,
  LAST_USED_EMAIL,
  LAST_USED_PICKUP_NOTES,
} from "../../../constants/cookie";
import {
  fetchLastUsedAddress,
  fetchLastUsedPhoneNumber,
  fetchLastUsedEmail,
  handleSetCookies,
  fetchLastUsedPickupNotes,
  locationToGraph,
  imagesToGraph,
  date_to_graph,
} from "../../../helpers/utils";
import { formatAddress } from "../../../helpers/address";
import RetailerSelect from "../../retailers/retailer-select";
import { update_fields, num_to_graph } from "../../../helpers/utils";
import Photos from "src/components/common/photos";
import Prices from "./Prices";
import UnitTypeDropdown from "src/components/unitType";
import Visibility from "./components/Visibility";
import PickupWindows from "src/components/pickupWindows";
import tzlookup from "tz-lookup";
import ProductBundle from "./ProductBundle";

const GET_TAGS = gql`
  query {
    getConditionTags {
      type
      label
    }
  }
`;

const GET_FULFILLMENT_TYPE_CHOICES = gql`
  query {
    getFulfillmentTypeChoices {
      type
      label
    }
  }
`;

const AddEditProduct = ({
  data,
  onSubmit,
  useRecentLocationInfo,
  onClose,
  autoSuggestStockImage,
  showPrices,
  isDonation,
}) => {
  const [skipReset, setSkipReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactNumber, setContactNumber] = useFormattedPhone("");
  const [contactEmail, setContactEmail] = useState("");
  const [values, setValues] = useState({});
  const [retailerName, setRetailerName] = useState();
  const [isPickupTimesOpen, setIsPickupTimesOpen] = useState(true);
  const togglePickupTimesOpen = () => setIsPickupTimesOpen((prev) => !prev);

  const [dropdowns, setDropdowns] = useState({
    categories: false,
    conditionTagTypes: false,
    fulfillment: false,
    status: false,
    unitType: false,
    recipientType: false,
  });

  let { data: conditionTagTypes } = useQuery(GET_TAGS);
  let { data: fulfillments } = useQuery(GET_FULFILLMENT_TYPE_CHOICES);

  const [categoryTreeValues, setCategoryTreeValues] = useState({
    checked: values.categoryTypes || [],
    expanded: data.categoryTypes || [],
  });

  const [tagTreeValues, setTagTreeValues] = useState({
    checked: values.conditionTagTypes || [],
    expanded: data.conditionTagTypes || [],
  });

  const [fulfillmentTreeValues, setFulfillmentTreeValues] = useState({
    checked: values.fulfillmentTypes || [],
    expanded: data.fulfillmentTypes || [],
  });

  const [recipientTypesTreeValues, setRecipientTypesTreeValues] = useState({
    checked: values.recipientTypes || [],
    expanded: data.recipientTypes || [],
  });

  const [bundleProducts, setBundleProducts] = useState(
    values?.productBundles || [],
  );

  const setItem = (name, value) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const queryParameters = new URLSearchParams(window.location.search);

  const isLocationRequired = () => {
    return (
      !values?.donorRetailerUUID || fulfillmentTreeValues.checked.length > 0
    );
  };

  const isEmailOrPhoneRequired = () => {
    return !values?.donorRetailerUUID || isLocationRequired();
  };

  const isFulfillmentRequired = () => {
    return !!values?.location?.address || !values?.donorRetailerUUID;
  };

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) {
      return;
    }

    if (skipReset) {
      return;
    }

    const categoryTypes = data.categories?.length
      ? data.categories.map((d) => d?.type)
      : data.categoryTypes?.length
      ? data.categoryTypes
      : [];

    setValues({
      title: data?.title || queryParameters.get("title"),
      description: data.description || "",
      fairMarketValue:
        data.fairMarketValue ||
        (queryParameters.get("price")
          ? {
              value: queryParameters.get("price"),
              currencyCode: "USD",
            }
          : null),
      quantity: data.quantity ?? queryParameters.get("quantity"),
      quantityPalletsPerTruckload: data?.quantityPalletsPerTruckload || 0,
      quantityBoxPerPallet: data?.quantityBoxPerPallet || 0,
      quantityIndividualItemPerBox: data?.quantityIndividualItemPerBox || 0,
      customRadiusInMiles: data?.customRadiusInMiles,
      minimumOrderQuantity: data?.minimumOrderQuantity,
      maxOrderQuantity: data?.maxOrderQuantity,
      availableQuantity:
        data?.availableQuantity ?? queryParameters.get("availableQuantity"),
      conditionTagTypes: data.conditionTags
        ? data.conditionTags?.map((d) => d?.type)
        : data.conditionTagTypes
        ? data.conditionTagTypes
        : [],
      fulfillmentTypes: data.fulfillmentTypes ? data.fulfillmentTypes : [],
      visibility: data.visibility ? data.visibility : [],
      recipientTypes: data.recipientTypes ? data.recipientTypes : [],
      unitType: data?.unitType || queryParameters.get("unitType"),
      lengthInches: data.lengthInches,
      widthInches: data.widthInches,
      heightInches: data.heightInches,
      weightLbs: data.weightLbs,
      categoryTypes: categoryTypes,
      location: data.location
        ? update_fields({
            contactEmail: (x) => x || "",
          })(data.location)
        : {
            contactEmail: useRecentLocationInfo ? fetchLastUsedEmail() : "",
            contactNumber: useRecentLocationInfo
              ? fetchLastUsedPhoneNumber()
              : "",
            address: useRecentLocationInfo
              ? fetchLastUsedAddress()
              : {
                  streetAddress1: "",
                  streetAddress2: "",
                  city: "",
                  state: "",
                  zip: "",
                },
            notes: useRecentLocationInfo ? fetchLastUsedPickupNotes() : "",
          },
      images: data.images,
      isNonprofitGift: data.isNonprofitGift,
      autoMatch: data.autoMatch,
      isMattress: data.isMattress,
      isInside: data.isInside,
      isBundle: data.isBundle,
      bundleProducts: data.bundleProducts,
      noResale: data.noResale && true,
      donorRetailerUUID:
        data.donorRetailer?.uuid || queryParameters.get("donorRetailerUUID"),
      sku: data?.sku || queryParameters.get("sku"),
      status: data?.status || "active",
      nextPostingDate: data?.nextPostingDate,
    });

    setRetailerName((_) => data.donorRetailer?.name);

    setCategoryTreeValues({
      checked: categoryTypes || [],
      expanded: categoryTypes || [],
    });

    setTagTreeValues({
      checked: data.conditionTagTypes || [],
      expanded: data.conditionTagTypes || [],
    });

    setFulfillmentTreeValues({
      checked: data.fulfillmentTypes || [],
      expanded: data.fulfillmentTypes || [],
    });

    setRecipientTypesTreeValues({
      checked: data.recipientTypes || [],
      expanded: data.recipientTypes || [],
    });

    setBundleProducts(data.productBundles || []);

    let email =
      data?.location?.contactEmail ||
      (useRecentLocationInfo ? fetchLastUsedEmail() : undefined);

    if (!!email) {
      setContactEmail(email);
    }

    let phone =
      data?.location?.contactNumber ||
      (useRecentLocationInfo ? fetchLastUsedPhoneNumber() : undefined);

    if (!!phone) {
      setContactNumber(phone);
    }

    setSkipReset(true);
  }, [data]);

  const onSubmitAddEditProduct = async () => {
    if (!values?.visibility || values?.visibility?.length === 0) {
      toast.error("Visibility cannot be empty");
      return;
    }

    if (!values?.unitType) {
      toast.error("Unit type required");
      return;
    }

    if (!values?.weightLbs) {
      toast.error("Weight requied");
      return;
    }

    if (
      isFulfillmentRequired() &&
      (!values?.fulfillmentTypes || values?.fulfillmentTypes.length == 0)
    ) {
      toast.error("Fulfillment Type Required");
      return;
    }

    if (
      isLocationRequired() &&
      (!values?.location || !values?.location?.address)
    ) {
      toast.error("Location with address required");
      return;
    }

    if (isEmailOrPhoneRequired()) {
      if (!values?.location?.contactEmail && !values?.location?.contactNumber) {
        toast.error("Email or Phone required");
        return;
      }
    }

    const onSuccessOrFailure = () => {
      setLoading(false);
    };
    setLoading(true);

    let submitValues = { ...values };

    await onSubmit(
      update_fields({
        noResale: (v) => (v ? true : false),
        isBundle: (v) => (v ? true : false),
        isMattress: (v) => (v ? true : false),
        isInside: (v) => (v ? true : false),
        autoMatch: (v) => (v ? true : false),
        heightInches: num_to_graph,
        widthInches: num_to_graph,
        lengthInches: num_to_graph,
        weightLbs: num_to_graph,
        customRadiusInMiles: num_to_graph,
        minimumOrderQuantity: num_to_graph,
        maxOrderQuantity: num_to_graph,
        quantityBoxPerPallet: num_to_graph,
        quantityIndividualItemPerBox: num_to_graph,
        quantityPalletsPerTruckload: num_to_graph,
        location: locationToGraph,
        images: imagesToGraph,
        nextPostingDate: date_to_graph,
      })(submitValues),
      onSuccessOrFailure,
    );
  };

  const isOnlyDigits = (value) => {
    return /^[()\-+\d\s]+$/.test(value);
  };

  const onChangeEmail = (e) => {
    const value = e.target.value;
    if (!value.length) {
      setContactEmail("");
      return;
    }
    setItem("location", {
      ...values.location,
      contactEmail: value,
    });
    setContactEmail(value);
    handleSetCookies(LAST_USED_EMAIL, value);
  };

  const onChangePhone = (e) => {
    const value = e.target.value;
    if (!value.length) {
      setContactNumber("");
      return;
    }

    if (isOnlyDigits(value)) {
      setItem("location", {
        ...values.location,
        contactNumber: value,
      });
      setContactNumber(value);
      handleSetCookies(LAST_USED_PHONE, value);
    }
  };

  const handleSetAddress = (value) => {
    let addr = {
      ...value,
      streetAddress2: values?.location?.address?.streetAddress2,
    };
    setItem("location", { ...values.location, address: addr });
    handleSetCookies(LAST_USED_ADDRESS, addr);
  };

  const handleSetAddress2 = (e) => {
    let addr = {
      ...values?.location?.address,
      streetAddress2: e.target.value,
    };
    setItem("location", {
      ...values?.location,
      address: addr,
    });
    handleSetCookies(LAST_USED_ADDRESS, addr);
  };

  const handleSetNotes = (e) => {
    const value = e.target.value;
    setItem("location", { ...values.location, notes: value });
    handleSetCookies(LAST_USED_PICKUP_NOTES, value);
  };

  const getTimezone = (address) => {
    if (!address) {
      return null;
    }

    return address.timezone || tzlookup(address?.latitude, address?.longitude);
  };

  const getPrices = () => {
    return {
      total: data?.total,
      totalDiscounts: data?.totalDiscounts,
      serviceFees: data?.serviceFees,
      promoCode: data?.promoCode,
      donationServiceFee: data?.donationServiceFee,
    };
  };

  return (
    <div className={styles.donateItemEdit}>
      <X
        className={styles.x}
        onClick={() => {
          onClose();
        }}
      />
      <Row>
        <Col
          xs={{ size: 12, order: 2 }}
          lg={{ size: 6, order: 1 }}
        >
          <Row>
            <Col>
              <Photos
                selected={values?.images || []}
                hidePreview
                onSelect={(images) => {
                  if (!images) {
                    return;
                  }
                  setItem("images", images);
                }}
                stockImageSearchTerm={values?.title}
                autoSuggestStockImage={autoSuggestStockImage}
              />
            </Col>
          </Row>
          <Row>
            <Col>{showPrices && <Prices prices={getPrices()} />}</Col>
          </Row>
        </Col>
        <Col
          xs={{ size: 12, order: 1 }}
          lg={{ size: 6, order: 2 }}
        >
          <Row>
            <Col>
              <label className={styles.label}>
                Title<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className={styles.input + " form-control"}
                value={values.title || ""}
                type="text"
                onChange={(e) => {
                  setItem("title", e.target.value);
                }}
              />
            </Col>
          </Row>
          {(({ productClass }) =>
            !!productClass && (
              <Row>
                <label
                  {...{ className: styles.label }}
                >{`Product Class: ${productClass.uuid}`}</label>
              </Row>
            ))(data)}
          <Row>
            <Col>
              <label className={styles.label}>Description</label>
              <textarea
                className={styles.textarea + " form-control"}
                value={values.description || ""}
                onChange={(e) => {
                  setItem("description", e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs={6}
              md={4}
            >
              <label className={styles.label}>
                FMV (per item)<span style={{ color: "red" }}>*</span>
              </label>
              <InputGroup>
                <InputGroupAddon
                  addonType="prepend"
                  style={{ height: 43 }}
                >
                  $
                </InputGroupAddon>

                <input
                  className={" form-control"}
                  style={{ height: 43 }}
                  type="number"
                  id="item-price"
                  placeholder="0.00"
                  name="fairMarketValue"
                  value={values.fairMarketValue?.value || ""}
                  onChange={(e) => {
                    const exp = new RegExp(/^[0-9]*(.[0-9]{0,2})?$/);
                    if (e.target.value === "" || exp.test(e.target.value)) {
                      setItem("fairMarketValue", {
                        currencyCode: "USD",
                        value: e.target.value,
                      });
                    }
                  }}
                />
              </InputGroup>
            </Col>

            <Col
              xs={6}
              md={4}
            >
              <label className={styles.label}>
                Status<span style={{ color: "red" }}>*</span>
              </label>
              <Dropdown
                required={true}
                isOpen={dropdowns.status}
                toggle={(_) => {
                  setDropdowns((prev) => ({
                    ...prev,
                    status: !prev.status,
                  }));
                }}
              >
                <DropdownToggle className={styles.dropdown}>
                  {values.status || "None"}
                  <ChevronDown />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={(_) => {
                      setItem("status", "active");
                    }}
                  >
                    Active
                  </DropdownItem>
                  <DropdownItem
                    onClick={(_) => {
                      setItem("status", "donated");
                    }}
                  >
                    Donated
                  </DropdownItem>
                  <DropdownItem
                    onClick={(_) => {
                      setItem("status", "inactive");
                    }}
                  >
                    Inactive
                  </DropdownItem>
                  <DropdownItem
                    onClick={(_) => {
                      setItem("status", "expired");
                    }}
                  >
                    Expired
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>

            <Col
              xs={6}
              md={4}
            >
              <label className={styles.label}>
                Visibility<span style={{ color: "red" }}>*</span>
              </label>
              <Visibility
                visibility={values.visibility}
                onChange={(vs) => {
                  setItem("visibility", vs);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs={6}
              lg={6}
            >
              <label className={styles.label}>
                Quantity<span style={{ color: "red" }}>*</span>
              </label>
              <div {...{ style: { display: "flex" } }}>
                <Col
                  xs={5}
                  lg={5}
                  {...{ style: { padding: 0 } }}
                >
                  <input
                    className={styles.input + " form-control"}
                    type="text"
                    value={values.availableQuantity || ""}
                    onChange={(e) => {
                      setItem("availableQuantity", e.target.value);
                    }}
                    onBlur={(_evt) =>
                      setItem(
                        "quantity",
                        Math.max(
                          +values.availableQuantity,
                          +(values.quantity || 0),
                        ).toString(),
                      )
                    }
                  />
                </Col>
                <Col
                  xs={2}
                  lg={2}
                  {...{
                    style: {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                >
                  {"/"}
                </Col>

                <Col
                  xs={5}
                  lg={5}
                  {...{ style: { padding: 0 } }}
                >
                  <input
                    className={styles.input + " form-control"}
                    type="text"
                    value={values.quantity ?? ""}
                    onChange={(e) => {
                      setItem("quantity", e.target.value);
                    }}
                    onBlur={(_evt) =>
                      setItem(
                        "availableQuantity",
                        Math.min(
                          +values.quantity,
                          +values.availableQuantity,
                        ).toString(),
                      )
                    }
                  />
                </Col>
              </div>
            </Col>

            <Col
              xs={3}
              lg={3}
            >
              <label className={styles.label}>Minimum Order Quantity</label>

              <input
                className={styles.input + " form-control"}
                type="number"
                step="1"
                value={values.minimumOrderQuantity || ""}
                onChange={(e) => {
                  setItem("minimumOrderQuantity", e.target.value);
                }}
              />
            </Col>
            <Col
              xs={3}
              lg={3}
            >
              <label className={styles.label}>Max Order Quantity</label>

              <input
                className={styles.input + " form-control"}
                type="number"
                step="1"
                value={values.maxOrderQuantity || ""}
                onChange={(e) => {
                  setItem("maxOrderQuantity", e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs={4}
              md={4}
            >
              <label className={styles.label}>
                Unit Type<span style={{ color: "red" }}>*</span>
              </label>
              <UnitTypeDropdown
                initialUnitType={values.unitType}
                onSelect={(u) => setItem("unitType", u)}
              />
            </Col>

            <Col
              xs={4}
              md={4}
            >
              <label className={styles.label}>Auto match?</label>
              <input
                className={styles.inputCheckbox}
                type="checkbox"
                style={{ width: "38px", height: "40px" }}
                checked={values.autoMatch || false}
                onChange={(e) =>
                  setValues({ ...values, autoMatch: e.target.checked })
                }
              />
            </Col>
            <Col
              xs={4}
              md={4}
            >
              <label className={styles.label}>Is a bundle?</label>
              <input
                className={styles.inputCheckbox}
                type="checkbox"
                style={{ width: "38px", height: "40px" }}
                checked={values.isBundle || false}
                onChange={(e) =>
                  setValues({ ...values, isBundle: e.target.checked })
                }
              />
            </Col>
          </Row>
          {(values.unitType === "truckload" ||
            values.unitType === "pallet" ||
            values.unitType === "large_pallet" ||
            values.unitType === "xl_pallet" ||
            values.unitType === "xxl_pallet" ||
            values.unitType === "box") && (
            <Row>
              <Col lg="4">
                <label className={styles.label}>
                  Items per Box<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className={styles.input + " form-control"}
                  type="text"
                  value={values.quantityIndividualItemPerBox || ""}
                  onChange={(e) => {
                    setItem("quantityIndividualItemPerBox", e.target.value);
                  }}
                />
              </Col>
              {(values.unitType === "truckload" ||
                values.unitType === "pallet" ||
                values.unitType === "large_pallet" ||
                values.unitType === "xl_pallet" ||
                values.unitType === "xxl_pallet") && (
                <Col lg="4">
                  <label className={styles.label}>
                    Boxes per Pallet
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className={styles.input + " form-control"}
                    type="text"
                    value={values.quantityBoxPerPallet || ""}
                    onChange={(e) => {
                      setItem("quantityBoxPerPallet", e.target.value);
                    }}
                  />
                </Col>
              )}
              {values.unitType === "truckload" && (
                <Col lg="4">
                  <label className={styles.label}>
                    Pallets per Truckload
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className={styles.input + " form-control"}
                    type="text"
                    value={values.quantityPalletsPerTruckload || ""}
                    onChange={(e) => {
                      setItem("quantityPalletsPerTruckload", e.target.value);
                    }}
                  />
                </Col>
              )}
            </Row>
          )}
          <Row>
            <Col lg="6">
              <label className={styles.label}>
                Categories<span style={{ color: "red" }}>*</span>
              </label>
              <CategoryDropdown
                isOpen={dropdowns.categories}
                toggle={() => {
                  setDropdowns((prev) => ({
                    ...prev,
                    categories: !prev.categories,
                  }));
                }}
                categoryTreeValues={categoryTreeValues}
                onCheck={(checked) => setItem("categoryTypes", checked)}
                setCategoryTreeValues={setCategoryTreeValues}
              />
            </Col>
            <Col lg="6">
              <label className={styles.label}>
                Condition<span style={{ color: "red" }}>*</span>
              </label>
              <Dropdown
                isOpen={dropdowns.conditionTagTypes}
                toggle={() => {
                  setDropdowns((prev) => ({
                    ...prev,
                    conditionTagTypes: !prev.conditionTagTypes,
                  }));
                }}
              >
                <DropdownToggle className={styles.dropdown}>
                  <div className="d-flex flex-wrap">
                    {tagTreeValues.checked?.map((x, i) => (
                      <div
                        key={i}
                        className={styles.tag + " mr-1"}
                      >
                        {x}
                      </div>
                    ))}
                  </div>
                  <ChevronDown />
                </DropdownToggle>

                <DropdownMenu>
                  <CheckboxTree
                    onlyLeafCheckboxes
                    icons={{
                      parentClose: null,
                      parentOpen: null,
                      leaf: null,
                    }}
                    nodes={
                      conditionTagTypes
                        ? conditionTagTypes.getConditionTags.map((x) => ({
                            value: x.type,
                            label: x.label,
                            className: "d-block w-100",
                          }))
                        : []
                    }
                    checked={tagTreeValues.checked}
                    expanded={tagTreeValues.expanded}
                    onCheck={(checked) => {
                      setTagTreeValues(() => ({
                        checked,
                        expanded: tagTreeValues.expanded,
                      }));
                      setItem("conditionTagTypes", checked);
                    }}
                    onExpand={(expanded) =>
                      setTagTreeValues(() => ({
                        expanded,
                        checked: tagTreeValues.checked,
                      }))
                    }
                  />
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={6}
            >
              <label className={styles.label}>Retailer</label>
              <RetailerSelect
                {...{
                  retailer: {
                    uuid: values?.donorRetailerUUID,
                    name: retailerName,
                  },
                  onSelect: (retailer) => {
                    setRetailerName((_) => retailer.name);
                    setItem("donorRetailerUUID", retailer.uuid);
                  },
                }}
              />
            </Col>
            <Col
              md={2}
              xs={6}
            >
              <label className={styles.label}>No Resale</label>
              <input
                className={styles.inputCheckbox}
                type="checkbox"
                style={{ width: "38px", height: "40px" }}
                checked={values.noResale || false}
                onChange={(e) =>
                  setValues({ ...values, noResale: e.target.checked })
                }
              />
            </Col>
            <Col
              md={4}
              xs={6}
            >
              <label className={styles.label}>SKU</label>
              <input
                className={styles.input + " form-control"}
                value={values.sku || ""}
                type="text"
                onChange={(e) => {
                  setItem("sku", e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <label className={styles.label}>Recipient Types</label>
              <Dropdown
                isOpen={dropdowns.recipientType}
                toggle={() => {
                  setDropdowns((prev) => ({
                    ...prev,
                    recipientType: !prev.recipientType,
                  }));
                }}
              >
                <DropdownToggle className={styles.dropdown}>
                  <div className="d-flex flex-wrap">
                    {recipientTypesTreeValues.checked?.map((x, i) => (
                      <div
                        key={i}
                        className={styles.tag + " mr-1"}
                      >
                        {x}
                      </div>
                    ))}
                  </div>
                  <ChevronDown />
                </DropdownToggle>

                <DropdownMenu>
                  <CheckboxTree
                    onlyLeafCheckboxes
                    nodes={[
                      {
                        value: "nonprofit",
                        label: "Nonprofit",
                        className: "d-block w-100",
                      },
                    ]}
                    icons={{
                      parentClose: null,
                      parentOpen: null,
                      leaf: null,
                    }}
                    checked={recipientTypesTreeValues.checked}
                    expanded={recipientTypesTreeValues.expanded}
                    onCheck={(checked) => {
                      setRecipientTypesTreeValues(() => ({
                        checked,
                        expanded: recipientTypesTreeValues.expanded,
                      }));
                      setItem("recipientTypes", checked);
                    }}
                    onExpand={(expanded) =>
                      setRecipientTypesTreeValues(() => ({
                        expanded,
                        checked: recipientTypesTreeValues.checked,
                      }))
                    }
                  />
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col lg="4">
              <label className={styles.label}>
                Fulfillment
                {isFulfillmentRequired() && (
                  <span style={{ color: "red" }}>*</span>
                )}
              </label>
              <Dropdown
                isOpen={dropdowns.fulfillment}
                toggle={() => {
                  setDropdowns((prev) => ({
                    ...prev,
                    fulfillment: !prev.fulfillment,
                  }));
                }}
              >
                <DropdownToggle className={styles.dropdown}>
                  <div className="d-flex flex-wrap">
                    {fulfillmentTreeValues.checked?.map((x, i) => (
                      <div
                        key={i}
                        className={styles.tag + " mr-1"}
                      >
                        {x}
                      </div>
                    ))}
                  </div>
                  <ChevronDown />
                </DropdownToggle>

                <DropdownMenu>
                  <CheckboxTree
                    onlyLeafCheckboxes
                    nodes={
                      fulfillments
                        ? fulfillments.getFulfillmentTypeChoices.map((x) => ({
                            value: x.type,
                            label: x.label,
                            className: "d-block w-100",
                          }))
                        : []
                    }
                    icons={{
                      parentClose: null,
                      parentOpen: null,
                      leaf: null,
                    }}
                    checked={fulfillmentTreeValues.checked}
                    expanded={fulfillmentTreeValues.expanded}
                    onCheck={(checked) => {
                      setFulfillmentTreeValues(() => ({
                        checked,
                        expanded: fulfillmentTreeValues.expanded,
                      }));
                      setItem("fulfillmentTypes", checked);
                    }}
                    onExpand={(expanded) =>
                      setFulfillmentTreeValues(() => ({
                        expanded,
                        checked: fulfillmentTreeValues.checked,
                      }))
                    }
                  />
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col lg="3">
              <label className={styles.label}>Nonprofit Gift</label>
              <input
                className={styles.inputCheckbox}
                type="checkbox"
                style={{ width: "38px", height: "40px" }}
                checked={values.isNonprofitGift || false}
                onChange={(e) =>
                  setValues({ ...values, isNonprofitGift: e.target.checked })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs="6"
              lg="3"
            >
              <label className={styles.label}>
                Weight (lbs)<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className={styles.input + " form-control"}
                type="number"
                step="0.01"
                value={values.weightLbs || ""}
                onChange={(e) => {
                  setItem("weightLbs", e.target.value);
                }}
              />
            </Col>
            <Col
              xs="6"
              lg="3"
            >
              <label className={styles.label}>Length (Inches)</label>
              <input
                className={styles.input + " form-control"}
                type="number"
                step="0.01"
                value={values.lengthInches || ""}
                onChange={(e) => {
                  setItem("lengthInches", e.target.value);
                }}
              />
            </Col>
            <Col
              xs="6"
              lg="3"
            >
              <label className={styles.label}>Width (Inches)</label>
              <input
                className={styles.input + " form-control"}
                type="number"
                step="0.01"
                value={values.widthInches || ""}
                onChange={(e) => {
                  setItem("widthInches", e.target.value);
                }}
              />
            </Col>
            <Col
              xs="6"
              lg="3"
            >
              <label className={styles.label}>Height (Inches)</label>
              <input
                className={styles.input + " form-control"}
                type="number"
                step="0.01"
                value={values.heightInches || ""}
                onChange={(e) => {
                  setItem("heightInches", e.target.value);
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col
              xs={3}
              md={3}
            >
              <label className={styles.label}>Is Mattress?</label>
              <input
                className={styles.inputCheckbox}
                type="checkbox"
                style={{ width: "38px", height: "40px" }}
                checked={values.isMattress || false}
                onChange={(e) =>
                  setValues({ ...values, isMattress: e.target.checked })
                }
              />
            </Col>
            <Col
              xs={3}
              md={3}
            >
              <label className={styles.label}>Is Inside?</label>
              <input
                className={styles.inputCheckbox}
                type="checkbox"
                style={{ width: "38px", height: "40px" }}
                checked={values.isInside || false}
                onChange={(e) =>
                  setValues({ ...values, isInside: e.target.checked })
                }
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col
              xs={12}
              md={6}
            >
              <label className={styles.label}>
                Address
                {(isLocationRequired() || isFulfillmentRequired()) && (
                  <span style={{ color: "red" }}>*</span>
                )}
              </label>
              <SearchLocationInput
                placeholdertext=""
                required={true}
                key={1}
                value={formatAddress(values.location?.address) || ""}
                setAddress={handleSetAddress}
                styles={{
                  height: "40px",
                  marginBottom: "0px",
                  padding: "0px 10px",
                  fontSize: "15px",
                }}
              />
            </Col>
            <Col
              xs={12}
              md={6}
            >
              <label className={styles.label}>Address Line 2</label>
              <Input
                className="form-control"
                type="text"
                required={false}
                name="addressLine2"
                value={values?.location?.address?.streetAddress2 || ""}
                onChange={handleSetAddress2}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              md="4"
            >
              <label className={styles.label}>Pickup Notes</label>
              <Input
                className="form-control"
                required={true}
                name="notes"
                value={values.location?.notes || ""}
                onChange={handleSetNotes}
              />
            </Col>
            <Col
              xs={12}
              md={4}
            >
              <label className={styles.label}>Email</label>
              <Input
                className="form-control"
                type="text"
                required={true}
                name="email"
                value={contactEmail || ""}
                onChange={onChangeEmail}
              />
            </Col>
            <Col
              xs={12}
              md={4}
            >
              <label className={styles.label}>Mobile</label>
              <Input
                className="form-control"
                type="text"
                required={true}
                name="mobile"
                value={contactNumber || ""}
                onChange={onChangePhone}
              />
            </Col>
          </Row>

          <hr />
          <Row>
            {isDonation ? (
              <></>
            ) : (
              <Col
                xs={12}
                md={4}
              >
                <label className={styles.label}>Next Posting Date</label>

                <input
                  className={styles.input + " form-control"}
                  type="date"
                  value={
                    values.nextPostingDate == null
                      ? null
                      : values.nextPostingDate.substring(0, 10)
                  }
                  onChange={(e) => {
                    setItem("nextPostingDate", e.target.value);
                  }}
                />
              </Col>
            )}
            {fulfillmentTreeValues.checked?.indexOf("donor_limited_delivery") >
            -1 ? (
              <Col
                xs={12}
                md={4}
              >
                <label className={styles.label}>Custom Radius in Miles</label>

                <input
                  className={styles.input + " form-control"}
                  type="number"
                  step="0.01"
                  value={values.customRadiusInMiles || ""}
                  onChange={(e) => {
                    setItem("customRadiusInMiles", e.target.value);
                  }}
                />
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Col>
      </Row>

      {data?.uuid && values.isBundle ? (
        <ProductBundle
          {...{
            bundleUUID: data?.uuid,
            bundleProducts,
            setBundleProducts,
            retailerUUID: values?.donorRetailerUUID,
          }}
        />
      ) : (
        <></>
      )}

      <hr />
      <Row>
        <Col>
          <label
            className={styles.label}
            style={{ cursor: "pointer" }}
            onClick={togglePickupTimesOpen}
          >
            Pickup Times {isPickupTimesOpen ? <ChevronUp /> : <ChevronDown />}
          </label>
          <Collapse isOpen={isPickupTimesOpen}>
            <PickupWindows
              hours={data?.pickupHours}
              timezone={getTimezone(values?.location?.address)}
              onSelect={(v) => setItem("pickupHours", v)}
            />
          </Collapse>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          {loading ? (
            <div className="d-flex justify-content-around mt-4">
              <Spinner />
            </div>
          ) : (
            <div className="d-flex justify-content-around mt-4">
              <img
                onClick={() => {
                  onClose();
                }}
                src={Back}
                alt="Back"
                style={{ width: 40, cursor: "pointer" }}
              />
              <img
                src={Check}
                alt="Edit"
                onClick={onSubmitAddEditProduct}
                style={{ width: 40, cursor: "pointer" }}
              />
            </div>
          )}
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AddEditProduct;
